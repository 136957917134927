var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader),expression:"loader"}]},[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"circle","step-size":"xs","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Personal Details","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Personal Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Surname","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false:null,"placeholder":"Enter Surname"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Other Names","label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"Other Names","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","placeholder":"Enter other names","state":errors.length > 0 ? false:null},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"country","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"country","options":_vm.countries,"label":"country_name"},on:{"input":function($event){return _vm.setState()}},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(_vm.states.length > 0)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"State of Origin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"State of Origin","label-for":"state","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"state","options":_vm.states,"label":"name"},on:{"input":function($event){return _vm.setLgas()}},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,210748136)})],1):_vm._e(),(_vm.lgas.length > 0)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"LGA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"LGA of Origin","label-for":"lga","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"lga","options":_vm.lgas,"label":"name"},on:{"input":function($event){return _vm.setLgas()}},model:{value:(_vm.selectedLGA),callback:function ($$v) {_vm.selectedLGA=$$v},expression:"selectedLGA"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3988271157)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"DOB (YYYY-MM-DD)","label-for":"dob"}},[_c('validation-provider',{attrs:{"name":"DOB","rules":"regex:^\\d{4}-\\d{2}-\\d{2}$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-1"},[_c('b-form-input',{attrs:{"id":"dob","type":"text","placeholder":"YYYY-MM-DD","show-decade-nav":""},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"show-decade-nav":"","button-only":"","right":"","locale":"en-US","aria-controls":"dob"},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Gender"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},_vm._l((_vm.genders),function(gender,index){return _c('el-option',{key:index,attrs:{"label":gender,"value":gender}})}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Contact Details","before-change":_vm.validationFormSponsor}},[_c('validation-observer',{ref:"sponsorRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Contact Details ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Main Mobile Number","label-for":"phone1"}},[_c('validation-provider',{attrs:{"name":"Main Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone1","state":errors.length > 0 ? false:null,"placeholder":"Enter Phone Number"},model:{value:(_vm.form.phone1),callback:function ($$v) {_vm.$set(_vm.form, "phone1", $$v)},expression:"form.phone1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Alternative Mobile Number","label-for":"phone2","rules":"integer:min:11|integer:max:11"}},[_c('b-form-input',{attrs:{"id":"phone2","placeholder":"Enter Alternative Phone Number"},model:{value:(_vm.form.phone2),callback:function ($$v) {_vm.$set(_vm.form, "phone2", $$v)},expression:"form.phone2"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Residential Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","placeholder":"Enter residential address","state":errors.length > 0 ? false:null},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Received Documents"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Tick All Submitted Documents ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":""}},[_c('b-form-checkbox',{attrs:{"value":1,"name":"flavour-3a"},model:{value:(_vm.form.is_cv_submitted),callback:function ($$v) {_vm.$set(_vm.form, "is_cv_submitted", $$v)},expression:"form.is_cv_submitted"}},[_vm._v(" Resume/Curriculum Vitae ")]),_c('b-form-checkbox',{attrs:{"value":1,"name":"flavour-3a"},model:{value:(_vm.form.is_edu_cert_submitted),callback:function ($$v) {_vm.$set(_vm.form, "is_edu_cert_submitted", $$v)},expression:"form.is_edu_cert_submitted"}},[_vm._v(" Educational Certificates ")]),_c('b-form-checkbox',{attrs:{"value":1,"name":"flavour-3a"},model:{value:(_vm.form.is_exp_cert_submitted),callback:function ($$v) {_vm.$set(_vm.form, "is_exp_cert_submitted", $$v)},expression:"form.is_exp_cert_submitted"}},[_vm._v(" Professional/Other Certificates ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }